import React, { Component } from 'react';
import Web3 from 'web3';
import './App.css';

class App extends Component {
  state = {
    buttonTop: 200,
    buttonLeft: 200,
  };

  componentDidMount() {
    this.loadBlockchainData();
    // Add click listener to play the song
    document.addEventListener('click', this.playSong);
  }

  componentWillUnmount() {
    // Remove click listener to avoid memory leaks
    document.removeEventListener('click', this.playSong);
  }

  loadBlockchainData = async () => {
    const web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/KEK'));
    const networkId = await web3.eth.net.getId();
    const networkName = this.getNetworkName(networkId);
    console.log("Network ID:", networkId);
    console.log("Network Name:", networkName);
  };
  
  getNetworkName = (networkId) => {
    const networkNames = {
      1: 'Mainnet',
      4: 'Rinkeby',
      5: 'Goerli',
      42: 'Kovan',
    };
    return networkNames[networkId] || 'Unknown';
  };

  connectWalletHandler = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        document.body.classList.add("connected");
      } catch (error) {
        console.error("User denied account access...");
      }
    } else {
      console.error("Non-Ethereum browser detected. Consider trying MetaMask!");
    }
  };

  moveButton = (e) => {
    const newX = Math.max(Math.random() * window.innerWidth - 100, 0);
    const newY = Math.max(Math.random() * window.innerHeight - 100, 0);
    this.setState({
      buttonTop: newY,
      buttonLeft: newX,
    });
  };

  playSong = () => {
    const audio = new Audio('/song.mp3'); // Ensure the song is placed in the public folder
    audio.play().catch(error => console.error("Error playing the song:", error));
  };

  render() {
    const { buttonTop, buttonLeft } = this.state;
    return (
      <div className="app">
        <header className="app-header">
          <h1>Welcome to the Chaos</h1>
          <button onClick={this.connectWalletHandler}>Connect to the Void</button>
          <button 
            style={{ position: 'absolute', top: buttonTop, left: buttonLeft }}
            onMouseMove={this.moveButton}
          >
            Catch me if you can
          </button>
        </header>
      </div>
    );
  }
}

export default App;
